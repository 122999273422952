import BookButton from './BookButton';
import SocialLinks from './SocialLinks';


function HeroComponent() {
    return (
        <div className="hero">
            <div className='herotxt'>
                <p className="htext" style={{
                    
                }}>
                    TAKING YOU A STEP AHEAD
                </p>
                <div className='mo'>
                    <BookButton />
                    <SocialLinks />
                </div>

            </div>
        </div>
    );
}

export default HeroComponent;
