import footer from '../imgs/footer.png';
  
function Footer() {
    return (
        <div className="">
            <img src={footer} width={'100%'} alt='footer'/>
        </div>
    );
}

export default Footer;
