import { Oval } from 'react-loader-spinner';

import { useEffect, useState } from 'react';

import logo from '../imgs/logo.png';
import qnaire from '../imgs/qnaire.png';
import '../css/invoice_style.css';
  

import Modal from 'react-modal';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';


// Modal.setAppElement("modalBlock");

function PageInfo() {
    return (
        <div>
            <p style={{
                fontSize: '3em',
                fontWeight: 'bold',
            }}>MOVING SERVICES</p>
            <img src={qnaire} className="" alt="questionaire" width={'20%'} />
        </div>
    );
}

function HRAInvoiceHeader({ client_name, pickup, delivery, phone, email, pickupDate, pickupTime }) {
    return (

        <section className='invoice_section header'>

            <h1> HRA Transportation Quotation </h1>
            <address style={{ textAlign: "left" }}>
                <p> {`Quote prepared for:   ${client_name}`} </p>
                <p> {`Phone:    ${phone}`}</p>
                <p> {`Email:    ${email}`}</p>
                <p> {`Pickup Address:  ${pickup}`} </p>
                <p> {`Delivery Address:  ${delivery}`} </p>
                <p> {`Pickup Date:  ${pickupDate}`} </p>
                <p> {`Pickup Time:  ${pickupTime}`} </p>
            </address>

            <span>
                <img alt="hra_transportation" src={logo} className="rounded float-right align-top" width={200} />
            </span>

        </section>
    );
}

function HRAInvoiceBody({ calcdfees, todaysDate, invoiceNum }) {
    const [calculatedfees, setCalculatedFees] = useState(calcdfees);


    // let table1OfJSONObjects = []
    // try {
    //     table1.map((item, idx) => {
    //         table1OfJSONObjects[idx] = JSON.parse(item)//convert table1 items to json objects and add to the new objects table
    //     })
    // } catch (error) {
    //     // console.log(error)
    // }

    // console.log("table1OfJSONObjects nowwwwwwwwwwwwwwwwwwwwww");
    // console.log(table1OfJSONObjects)
    // console.log(":___________________________________________:")

    // const distanceToPickUp = 10;
    // const distanceToDropOff = 20;
    // const totalTripDistance = 60;
    // //get price from the server here
    // const reqBody = `{"selectedItems": ${JSON.stringify(table1OfJSONObjects)}, "tripInfoDto": {"distanceToPickUp": ${distanceToPickUp},"distanceToDropOff":${distanceToDropOff},"totalTripDistance": ${totalTripDistance}}}`


    // //backend request
    // const res = axios.post('https://hratransportations.tech/calculateFee', reqBody, {
    //     headers: {
    //         // Overwrite Axios's automatically set Content-Type
    //         'Content-Type': 'application/json'
    //     }
    // }).then(
    //     r => {
    //         console.log("CalculatedFees\n" + r.data);
    //         setCalculatedFees(r.data);
    //     }
    // );



    return (
        <section>
            <article>
                <table className="firstTable">
                    <tr>
                        <th><span >Quote #</span></th>
                        <td><span >{invoiceNum}</span></td>
                    </tr>
                    <tr>
                        <th><span >Date</span></th>
                        <td><span >{todaysDate}</span></td>
                    </tr>
                    {/* <tr>
                        <th><span >Amount Due</span></th>
                        <td><span id="prefix" >R</span><span>{totaldue + 0.15 * totaldue}</span></td>
                    </tr> */}
                </table>

                <table className="secondTable">
                    {/* get calculated pricing from backend */}
                    <thead>
                        <tr>
                            <th><span >Item</span></th>
                            {/* <th><span >Description</span></th>
                            <th><span >Rate</span></th>
                            <th><span >Quantity</span></th> */}
                            <th><span >Price</span></th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td><a className="cut"></a><span >Weight Charges</span></td>
                            {/* <td><span ></span></td>
                            <td><span data-prefix>R</span><span >-</span></td>
                            <td><span >-</span></td> */}
                            <td><span data-prefix>R</span>
                            <span>{parseFloat(calculatedfees.weightCharges).toFixed(2)}</span></td>
                        </tr>


                        <tr>
                            <td><a className="cut"></a><span >Duration Charges</span></td>
                            {/* <td><span ></span></td>
                            <td><span data-prefix>R</span><span >-</span></td>
                            <td><span >-</span></td> */}
                            <td><span data-prefix>R</span><span>{parseFloat(calculatedfees.durationCharges).toFixed(2)}</span></td>
                        </tr>

                        <tr>
                            <td><a className="cut"></a><span >Items Charges</span></td>
                            {/* <td><span ></span></td>
                            <td><span data-prefix>R</span><span >-</span></td>
                            <td><span >-</span></td> */}
                            <td><span data-prefix>R</span><span>{parseFloat(calculatedfees.itemsCharges).toFixed(2)}</span></td>
                        </tr>

                        <tr>
                            <td><a className="cut"></a><span >Callout Charges</span></td>
                            {/* <td><span ></span></td>
                            <td><span data-prefix>R</span><span >-</span></td>
                            <td><span >-</span></td> */}
                            <td><span data-prefix>R</span><span>{parseFloat(calculatedfees.calloutCharges).toFixed(2)}</span></td>
                        </tr>

                        <tr>
                            <td><a className="cut"></a><span >Fuel Charges</span></td>
                            {/* <td><span ></span></td>
                            <td><span data-prefix>R</span><span >-</span></td>
                            <td><span >-</span></td> */}
                            <td><span data-prefix>R</span><span>{parseFloat(calculatedfees.fuelCharges).toFixed(2)}</span></td>
                        </tr>


                    </tbody>
                </table>
                <table className="firstTable">
                    <tr>
                        <th><span >Subtotal</span></th>
                        <td><span data-prefix>R</span><span>{parseFloat(calculatedfees.totalCharges).toFixed(2)}</span></td>
                    </tr>
                    <tr>
                        <th><span >Total Balance Due</span></th>
                        <td><span data-prefix>R</span><span>{parseFloat(calculatedfees.totalCharges).toFixed(2)}</span></td>
                    </tr>
                </table>
            </article>

            <aside>
                <h1 id="notes">Additional Notes</h1>
                <div >
                    <p>Pay on Pickup</p>
                </div>
            </aside>

        </section>
    )
}

function TripQuote({ modalStatus, tripFormDetails, pAdd, dAdd, dtP, dtD, ttD }) {

    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(modalStatus);
    const [calcFees, setCalcFees] = useState({});

    // let tripFormDetails = JSON.parse(localStorage.getItem("formtripValue"));
    let today = new Date();
    const tdate = today.getDate() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getFullYear();
    const invnum = `${today.getHours()}${today.getMinutes()}-${today.getDate()}${parseInt(today.getMonth() + 1)}`;


    //calculate fees
    let table1OfJSONObjects = []
    try {
        tripFormDetails.selectedItems.map((item, idx) => {
            table1OfJSONObjects[idx] = JSON.parse(item)//convert table1 items to json objects and add to the new objects table
        })
    } catch (error) {
        // console.log(error)
    }

    //calculate these dynamically, not this hardcoding
    const distanceToPickUp = dtP;
    const distanceToDropOff = dtD;
    const totalTripDistance = ttD;
    //get price from the server here
    const reqBody = `{"selectedItems": ${JSON.stringify(table1OfJSONObjects)}, "tripInfoDto": {"distanceToPickUp": ${distanceToPickUp},"distanceToDropOff":${distanceToDropOff},"totalTripDistance": ${totalTripDistance}}}`


    //backend request
    const res = axios.post('https://hratransportations.tech/calculateFee', reqBody, {
        headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
        }
    }).then(
        r => {
            console.log("CalculatedFees\n" + r.data);
            setCalcFees(r.data);
        }
    );



    const openModal = () => {
        setIsOpen(true);
    }

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        localStorage.removeItem("formtripValue");
        setIsOpen(false);
    }

    //send this quote via email
    const sendEmail = (pkey, skey, tkey) => {
        emailjs.send(skey, tkey, { "Quotation #": invnum, tripFormDetails, 'Pickup': pAdd, 'Delivery': dAdd, "CalculatedFees": calcFees, }, { publicKey: pkey }).then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);

                alert("HRA Transportation Admin received this Trip Request, They will be in touch shortly");
                window.open("/", "_self");

            },
            (error) => {
                console.log('FAILED...', error);
            },);
    }

    // Handle Confirm Trip Booking Service
    const handleConfirmTripBooking = () => {
        console.log("Confirming Trip Booking...");
        console.log(tripFormDetails);



        alert("Confirming this sents your details to HRA Transportations and they will be in touch with you to confirm the pickup. Kindly keep the Quotation that will be downloaded on confirming this. Thank you kindly.")
        //send this quotation info to the email using emailJS

        sendEmail('gH-ZW-VxEgk5RvC68', 'service_ei2lb18', 'template_96v1cv9')


        //donwload this quotation
        handleDownloadPDF();
    }

    const handleDownloadPDF = () => {
        const input = document.getElementById('pdf-content');
        // Specify the id of the element you want to convert to PDF

        const today = new Date();

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save(`hra_quotation_${today.getMinutes()}${parseInt(today.getMonth() + 1)}${today.getFullYear()}.pdf`);
            // Specify the name of the downloaded PDF file
        });
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '40%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            marginTop: '3em',
            height: '70vh',
            width: '80vh',
            transform: 'translate(-50%, -50%)',
        },
    };



    return (
        <div>
            <button onClick={openModal}>View Quotation</button>
            <div>

                <Modal

                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    ariaHideApp={false}
                    style={customStyles}
                    contentLabel="Trip Quotation Modal">

                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Trip Quotation</h2>

                    <div className='invoice_section'>
                        <div style={{ display: "inline-flex", paddingLeft:"5em", }}>
                            <button onClick={closeModal}>Cancel</button>
                            <button onClick={handleConfirmTripBooking}>Confirm TripBooking</button>
                            <button onClick={handleDownloadPDF}>Download</button>
                        </div>
                        <div id='pdf-content' style={{
                            padding: "1em",
                            margin: "0.5em auto",
                        }}>
                            {/* <HRAInvoiceHeader /> */}
                            <HRAInvoiceHeader client_name={tripFormDetails.fullname} phone={tripFormDetails.phone} pickup={pAdd} delivery={dAdd} email={tripFormDetails.email} pickupTime={tripFormDetails.time} pickupDate={tripFormDetails.date} />
                            <HRAInvoiceBody calcdfees={calcFees} todaysDate={tdate} invoiceNum={invnum} />
                        </div>
                    </div>


                </Modal>

            </div>
        </div>
    );
}

function InventoryBlock() {

    const inv = [
        {
            "id": 1,
            "name": "Refrigerator",
            "averageMass": 135.0,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 2,
            "name": "Washing Machine",
            "averageMass": 90.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 3,
            "name": "Dishwasher",
            "averageMass": 56.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 4,
            "name": "Microwave Oven",
            "averageMass": 20.0,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 5,
            "name": "Oven Range",
            "averageMass": 67.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 6,
            "name": "Air Conditioner",
            "averageMass": 54.0,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 7,
            "name": "Television",
            "averageMass": 9.05,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 8,
            "name": "Vacuum Cleaner",
            "averageMass": 6.75,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 9,
            "name": "Toaster",
            "averageMass": 1.6,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 10,
            "name": "Coffee Maker",
            "averageMass": 3.4,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 11,
            "name": "Sofa Couch",
            "averageMass": 67.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 12,
            "name": "Bed",
            "averageMass": 67.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 13,
            "name": "Dining Table",
            "averageMass": 56.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 14,
            "name": "Dining Chairs",
            "averageMass": 6.75,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 15,
            "name": "Coffee Table",
            "averageMass": 27.0,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 16,
            "name": "Bookcase Shelving Unit",
            "averageMass": 45.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 17,
            "name": "Wardrobe Armoire",
            "averageMass": 90.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 18,
            "name": "Desk",
            "averageMass": 45.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 19,
            "name": "Nightstand",
            "averageMass": 9.05,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 20,
            "name": "Dresser",
            "averageMass": 67.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 21,
            "name": "TV Stand/Entertainment Center",
            "averageMass": 45.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 22,
            "name": "Cabinet",
            "averageMass": 45.5,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        },
        {
            "id": 23,
            "name": "Side Table",
            "averageMass": 9.05,
            "doe": "2024-06-12T14:48:45",
            "uoe": "wavhudi",
            "dlu": "2024-06-12T14:48:45",
            "ulu": "wavhudi",
            "defaultFee": 0.5,
        }
    ]

    return (
        <div style={{ height: "280px", overflow: "scroll", border: "2px solid #FEA403" }}>

            {

                inv.map((item, idx) => {
                    return (

                        <div className='inventoryItem' style={{
                            margin: "0 0 0 1em",
                            textAlign: "left",
                            justifyContent: 'space-evenly',
                            width: "100%",
                            // maxWidth:"25em",
                        }} key={idx}>
                            <label htmlFor="inventoryItem" style={{ display: "inline", padding: "0" }}>{item.name}</label>
                            <span style={{ margin: "0" }}>
                                <input type='checkbox' name="selectedItems[]" style={{ padding: "0", width: "3em", }}
                                    value={'{"itemName":"' + item.name + '","itemMass":' + item.averageMass + ',"itemCount":">=1"}'} />
                                <input type='num' min='0' placeholder='item count; coming soon' style={{
                                    width: "12em", padding: "0",
                                }} />
                            </span>
                        </div>

                    )
                })
            }
        </div>
    )
}

// distance calculator
function distance(lat1, lon1, lat2, lon2) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;

        dist = dist * 1.609344

        return dist;
    }
}


function TripForm() {

    const [tripFormDetails, setTripFormDetails] = useState({})

    const [pv, setPv] = useState(null)
    const [dv, setDv] = useState(null)

    let pickupCoord = {}, deliveryCoord = {};
    let dstP = "";
    let dstD = "";
    let totD = "";

    // pickup
    //on pv change
    useEffect(() => {
        try {
            geocodeByPlaceId(pv.value.place_id)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    // setPC({ lat, lng });
                    pickupCoord = { lat, lng };
                    localStorage.setItem("pickup", JSON.stringify(pickupCoord))
                    localStorage.setItem("padd", pv.label)

                    console.log(pickupCoord)
                    console.log('pv:::' + lat + '\n' + lng);
                    console.log(pv);
                    setTimeout(() => {

                        //set address value 
                        // pcAdd = pv.label;
                        // console.log(pcAdd)

                        console.log("ppppppppppppppppppppppppppppppcoord\n" + JSON.stringify(pickupCoord));
                        dstP = distance(-26.1929, 28.0304, pickupCoord.lat, pickupCoord.lng) //wits coordinates -26.1929,28.0304 to pickup

                        localStorage.setItem("dstP", dstP)

                        console.log("pickup distance::>" + dstP)
                    }, 5000);
                }
                );

        } catch (e) { console.log(e) }
    }, [pv]);

    //    dropoff
    //on dv change
    useEffect(() => {
        try {
            geocodeByPlaceId(dv.value.place_id)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    // setDC({ lat, lng });
                    deliveryCoord = { lat, lng };
                    localStorage.setItem("dadd", dv.label);
                    console.log(deliveryCoord);
                    console.log('dv:::' + lat + '\n' + lng);
                    console.log(dv);

                    setTimeout(() => {

                        //set address value
                        // doAdd = dv.label;
                        // console.log(doAdd)


                        console.log("ddddddddddddddddddddddddddddddddddd\n" + JSON.stringify(deliveryCoord));

                        pickupCoord = JSON.parse(localStorage.getItem("pickup"))
                        // console.log(pickupCoord)

                        dstD = distance(pickupCoord.lat, pickupCoord.lng, deliveryCoord.lat, deliveryCoord.lng) //pickup to dropoff
                        console.log(dstD)
                        localStorage.setItem("dstD", dstD)

                        totD = Number(dstP) + Number(dstD) + Number(distance(deliveryCoord.lat, deliveryCoord.lng, -26.1929, 28.0304))//wits-pickup-dropoff-wits::path
                        localStorage.setItem("totD", totD)

                        console.log("drop distance::>" + dstD)
                        console.log("total distance::>" + totD)
                    }, 5000);
                }
                );



        } catch (e) { console.log(e) }
    }, [dv]);



    const handleViewQuote = (e) => {
        // console.log(e);
        e.preventDefault();

        const tripForm = document.getElementById('tripform');

        console.log(require('form-serialize')(tripForm, { hash: true }));
        setTripFormDetails(require('form-serialize')(tripForm, { hash: true }));


        localStorage.setItem("formtripValue", JSON.stringify(require('form-serialize')(tripForm, { hash: true })));


    }

    const handleAdditionalPackaging = (e) => {
        console.log("Additional Packaging Required? " + e.target.value);

        if (e.target.value == "yes") {
            const additional_packaging = document.getElementById("additional_packaging_desc");

            additional_packaging.style.display = 'block';
            console.log(additional_packaging.style.display);
        } else {
            const additional_packaging = document.getElementById("additional_packaging_desc");

            additional_packaging.style.display = 'none';
            console.log(additional_packaging.style.display);
        }
    }


    return (
        <div>
            <p>Kindly provide us with the following info:</p>
            <form id='tripform'>

                <label htmlFor="fullname">Fullname</label>
                <input id="fullname" type='text' name="fullname" placeholder='your fullname here' />

                <label htmlFor="email">Email</label>
                <input id="email" type='email' name="email" placeholder='your contact email address' />

                <label htmlFor="phone">Phone</label>
                <input id="phone" type='tel' name="phone" placeholder='eg. +27612345678' />

                <label htmlFor="pickup_address">Pickup Address</label>
                {/* <input id="pickup_address" type='text' name="pickup_address" placeholder='eg. 1 Jan Smuts Ave, Braamfontein, 2001' /> */}
                <GooglePlacesAutocomplete

                    apiKey='AIzaSyDRJSZ1cxzcQCP4v9UQg8gVnLmKIv2ebGs'
                    selectProps={{
                        pv,
                        onChange: setPv,
                    }} />

                <label htmlFor="delivery_address">Delivery Address</label>
                {/* <input id="delivery_address" type='text' name="delivery_address" placeholder='eg. 1 Jan Smuts Ave, Braamfontein, 2001' /> */}
                <GooglePlacesAutocomplete

                    apiKey='AIzaSyDRJSZ1cxzcQCP4v9UQg8gVnLmKIv2ebGs'
                    selectProps={{
                        dv,
                        onChange: setDv,
                    }} />

                <label htmlFor="date">Pickup Date</label>
                <input id="date" type='date' name="date" />

                <label htmlFor="time">Pickup Time</label>
                <input id="time" type='time' name="time" />

                <label htmlFor="inventory_desc">Inventory Description</label>
                {/* <input id="inventory_desc" type='text' name="inventory_desc" placeholder='eg. bed, fridge, couch,...' /> */}
                <InventoryBlock />

                <label htmlFor="budget_amount">What's your budget in Rand?</label>
                <input id="budget_amount" type='number' min='0' name="budget_amount" placeholder='eg.400 for R400' />

                <label htmlFor="stairs_flight_count">How many flight of stairs?</label>
                <input id="stairs_flight_count" type='number' min="0" name="stairs_flight_count" placeholder='0 if no stairs are involved...' />

                <label htmlFor="additional_packaging">Do you require additional packaging?</label>
                <select id="additional_packaging" name="additional_packaging" onChange={handleAdditionalPackaging}>
                    <option value='no'>No</option>
                    <option value='yes'>Yes</option>
                </select>

                <div id='additional_packaging_desc' style={{ display: 'none', }}>
                    <label htmlFor="packaging_desc">What packaging do you require?</label>
                    <input id="packaging_desc" type='text' name="packaging_desc" placeholder='describe the additional packaging required' />

                </div>

            </form>
            <div id='modalBlock'></div>
            <div onClick={handleViewQuote}>

                <TripQuote modalStatus={false} tripFormDetails={tripFormDetails} pAdd={localStorage.getItem("padd")} dAdd={localStorage.getItem("dadd")} dtP={localStorage.getItem("dstP")} dtD={localStorage.getItem("dstD")} ttD={localStorage.getItem("totD")} />
            </div>
        </div>
    );

}


function TripDetails() {

    return (
        <div className="hero" >
            <PageInfo style={{ float: "left" }} />
            <TripForm />
            <div id="appElement"></div>
        </div>
    );
}

export default TripDetails;
