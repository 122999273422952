import logo from '../imgs/logo.png';

function NavItem({ id, text, status, linkto }) {
    return (
        <li
            className="navitem"
            key={id}
            style={{
                margin: '2.3em',
                padding: '1em',
                background: status ? '#FEA403' : '#fff'
            }}
        >
            <a href={linkto}
                style={{
                    fontSize: '1em',
                    textDecoration: 'none',
                }}
            >{text}</a>
        </li>
    )
}

function NavBar() {
    return (
        <div className="navbar" style={{ margin: '0', padding: '0', maxWidth: "80%", }}>
            <ul style={{ margin: '0', padding: '0.2em', textAlign: "center", }}>
                <a href="/">
                    <img src={logo} className="navLogo" alt="logo" width={200}/>
                </a>

                <NavItem id="1" text="Home" status={true} linkto="/" style={{ marginLeft: '3em', }} />
                {/* <NavItem id="2" text="AboutUs" status={false} linkto="/aboutus" />
                <NavItem id="3" text="Services" status={false} linkto="/services" /> */}

                <a className="navitem" href="/tripbooking">
                    <button style={{
                        // background: '#FEA403',
                        padding: '1em',
                        border: 'none',
                    }}>
                        Book A Bakkie
                    </button>
                </a>

                {/* <SocialLinks /> */}

            </ul>
        </div>
    );
}

export default NavBar;
