import delivery from '../imgs/ddd.png';
import BookButton from './BookButton';
import SocialLinks from './SocialLinks';


function DeliveryServices() {
    return (
        <div className="deliveryservices" style={{
            marginTop: "3em",
            padding: '3em 15em',
            display: 'inlineflex',

        }}>
            <img src={delivery} className="mo" alt="delivery" width={"40%"} />

            <div className="dservices" >
                <div>
                    <p style={{
                        fontSize: '2em',
                        fontWeight: 'bold',

                    }}>
                        DELIVERY SERVICES
                    </p>

                    <a href='/tripbooking'>
                        <button style={{
                            background: '#FEA403',
                            padding: '1em',
                            marginLeft: '2.5em',
                            border: 'none',
                        }}>
                            Book A Bakkie
                        </button>
                    </a>
                </div>
                <SocialLinks />
            </div>
        </div>
    );
}

export default DeliveryServices;
