import about from '../imgs/about_d.png';
import shipping from '../imgs/sss_cleanup.png';


function AboutUs() {
    return (
        <div className="aboutus" >
            <img className="aboutImg" src={about} alt="about"/>
            {/* <img className="shippingImg" src={shipping} alt="shipping" /> */}
        </div>
    );
}

export default AboutUs;
