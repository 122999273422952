import { Link } from "react-router-dom";


import logo from '../imgs/logo.png';


function BookButton() {
    return (
        <div className="">

            {/* <img src={logo} className="" alt="logo" width={200} /> */}
            <Link to="/tripbooking">
                <button style={{
                    background: '#FEA403',
                    padding: '1em',
                    marginLeft: '2.5em',
                    border: 'none',
                }}
                >
                    Book A Bakkie
                </button>
            </Link>

        </div>
    );
}

export default BookButton;
