import { Link } from "react-router-dom";


import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";


  

function SocialLinks() {
    return (
        <div className="socials">
            <ul style={{ listStyleType: 'none', }}>
                <li><a target="_blank" href="https://www.instagram.com/hra_transportations?igsh=eHE5MG1oMWsydno="><FaInstagram /></a></li>
                <li><a target="_blank" href="https://www.tiktok.com/@hra.trasportation?_t=8nLSAn8ce2w&_r=1"><FaTiktok /></a></li>
                <li><a target="_blank" href="https://wa.me/message/OMWTFIX6JKVTL1"><FaWhatsapp /></a></li>
            </ul>
        </div>
    );
}

export default SocialLinks;
