import { Link } from "react-router-dom";

  
import NavBar from './NavBar';
import HeroComponent from './HeroComponent';
import AboutUs from './AboutUs';
import DeliveryServices from './DeliveryServices';
import Footer from './Footer';
import TripDetails from "./TripDetails";

function App() {
  return (
    <div className="App">
      <NavBar />
      <div style={{
        background:'#8C8C8C'
      }}>

        <TripDetails />
        <p style={{ 
          background: '#FEA403',
          padding: "0.2em",
          width: "100%",
          margin: '1em auto',
        }}>*Kindly Note: Any additional item in the inventory not listed will increase the price</p>

        <hr style={{
          color: '#FEA403',
          background: '#FEA403',
          padding: "0.2em",
          width: "70%",
        }} />

      </div>
      <Footer />

    </div>
  );
}

export default App;
