import { Link } from "react-router-dom";

// import logo from '../imgs/logo.svg';
import truck from '../imgs/truck.png';

import NavBar from './NavBar';
import HeroComponent from './HeroComponent';
import AboutUs from './AboutUs';
import DeliveryServices from './DeliveryServices';
import Footer from './Footer';

function HomePage() {
  return (
    <div className="App">
      <header className="App-header">
        <section className="homePage1"        >
          <NavBar />
          <HeroComponent />
        </section>
        <AboutUs />
        <hr style={{
          color: '#FEA403',
          background: '#FEA403',
          padding: "0.2em",
          width: "70%",
        }} />
        <DeliveryServices />
        <Footer />
      </header>
    </div>
  );
}

export default HomePage;
